import React from 'react'
import styles from './Management.module.scss'
import image from '../../image/Management/img.png'

export const Management = ({isOpen}) => {

    return (
        <section className={styles.main}>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.box_info}>
                            <h3 className={styles.title}>Системы, которые мы монтируем, легко <span>настраивать и управлять</span></h3>
                            <p className={styles.text}>Управляйте отоплением Вашего дома удаленно с мобильного телефона</p>
                            <button className={styles.button} type='button' onClick={isOpen}>заказать систему</button>
                    </div>
                    <div className={styles.box_image}>
                        <img className={styles.image} src={image} alt='телефон'/>
                    </div>
                </div>
            </div>
        </section>
        
        
    )
}
import React, {useState} from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.svg'
import icon from '../../image/Header/logo.png'

export const Header = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    }

    window.addEventListener('scroll', toggleVisible);

    return (
    <header  className={`${header.header} ${visible && header.header_scroll}`}>
            <div className={header.box}>
                <a href='#initial'>
                    <img className={header.logo} src={logo} alt = 'логотип'/>
                    <img className={header.icon} src={icon} alt = 'логотип'/>
                </a>
                <nav className={header.nav}>
                    <a className={header.link} href='#Services'>Услуги</a>
                    <a className={header.link} href='#Price'>Виды систем</a>
                    <a className={header.link} href='#Portfolio'>Работы</a>
                    <a className={header.link} href='#Advantages'>Преимущества</a>
                    <a className={header.link} href='#Deadlines'>Как заказать</a>
                </nav>
                <div className={header.box_phone}>
                    <a className={header.link_phone} itemProp="telephone" href="tel:+79895915371" target='_blank' rel='noopener noreferrer'>+7 (989) 591-53-71</a>
                </div>
                <button className={header.button} type='button' onClick={isOpen} aria-label='открыть меню'/>
            </div>         
    </header>
    );
};
import React from 'react'
import styles from './MenuMobile.module.scss';
import logo from '../../image/Header/logo.svg'

export const MenuMobile = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    
                    <a className={styles.link} href='#Services' onClick={onClose}>Услуги</a>
                    <a className={styles.link} href='#Price' onClick={onClose}>Виды систем</a>
                    <a className={styles.link} href='#Portfolio' onClick={onClose}>Работы</a>
                    <a className={styles.link} href='#Advantages' onClick={onClose}>Преимущества</a>
                    <a className={styles.link} href='#Deadlines' onClick={onClose}>Как заказать</a>
                </nav>
        </div>
    );
}

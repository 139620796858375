import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {Advantages} from '../../components/Advantages/Advantages'
import { Portfolio } from '../../components/Portfolio/Portfolio'
import { Services } from '../../components/Services/Services'
import { Practical } from '../../components/Practical/Practical'
import { Management } from '../../components/Management/Management'
import { VideoClip } from '../../components/VideoClip/VideoClip'
import {Deadlines} from '../../components/Deadlines/Deadlines'
import image from '../../image/Main/img.webp'


export const Main = ({isOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <div className={styles.box}>
            <img className={styles.image} src={image} alt='ромашка'/>
                <div className={styles.box_info}>
                    <h3 className={styles.title}>Современные инженерные системы без предоплаты!</h3>
                    <p className={styles.subtitle}>Без посредников и&#160;переплат!</p>
                    <button className={styles.button} type='button' onClick={isOpen}>заказать систему</button>
                </div>
                
            </div>
            <Services
                isOpen={isOpen}
            />
            <Management
                isOpen={isOpen}
            />
            <Advantages/>
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <VideoClip/>
            <Deadlines
                isOpen={isOpen}
            />
            <Practical/>
        </section>
    )
}
import React from 'react'
import styles from './ListBoilers.module.scss'
import icon_1 from '../../image/ListBoilers/img_1.png'
import icon_2 from '../../image/ListBoilers/img_2.png'
import icon_3 from '../../image/ListBoilers/img_3.png'
import icon_4 from '../../image/ListBoilers/img_4.png'

export const ListBoilers = () => {

    return (
        <ul className={styles.list}>
            <li className={styles.item}>
                <img className={styles.image}  src={icon_1} alt='иконка'/>
                <h3 className={styles.title}>Электрическое топливо</h3>
                <div className={styles.info}>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Не требует значительных финансовых вложений</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Не нуждается в специальном обслуживании</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Экологично</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Работает бесшумно</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Простота в управлении</p>
                    </div>
                </div>
            </li>
            <li className={styles.item}>
                <img className={styles.image}  src={icon_2} alt='иконка'/>
                <h3 className={styles.title}>Газовое топливо</h3>
                <div className={styles.info}>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Газ - самый дешевый вид топлива</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Длительный срок службы котла</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Простота обслуживания системы</p>
                    </div>
                </div>
            </li>
            <li className={styles.item}>
                <img className={styles.image}  src={icon_3} alt='иконка'/>
                <h3 className={styles.title}>Комбинированное топливо</h3>
                <div className={styles.info}>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Комбинированные котлы работают сразу на нескольких видах топлива</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Это самый выгодный вариант(при отсутствии газа)</p>
                    </div>
                </div>
            </li>
            <li className={styles.item}>
                <img className={styles.image}  src={icon_4} alt='иконка'/>
                <h3 className={styles.title}>Твердое топливо</h3>
                <div className={styles.info}>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Минимальные эксплуатационные затраты</p>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.circle}></div>
                        <p className={styles.text}>Хороший вариант при отсутствии газа</p>
                    </div>
                </div>
                
            </li>
        </ul>                   
    )
}
import React from 'react'
import styles from './Advantages.module.scss'
import image_1 from '../../image/Advantages/image_1.svg'
import image_2 from '../../image/Advantages/image_2.svg'
import image_3 from '../../image/Advantages/image_3.svg'
import image_4 from '../../image/Advantages/image_4.svg'
import image_5 from '../../image/Advantages/image_5.svg'

export const Advantages = () => {

    return (
        <section className={styles.main} id='Advantages'>
            <div className={styles.container}>
                <div className={styles.box}>
                    <h2 className={styles.title}>5</h2>
                    <h4 className={styles.subtitle}>причин довериться нам</h4>
                    <p className={styles.text}>Наш принцип - качественный подход к работе. Мы монтируем системы отопления, водоснабжения и канализации так, чтобы в вашем доме всегда было тепло, уютно и вы никогда не сталкивались с проблемами в процессе эксплуатации.</p>
                </div>
                <ul  className={styles.list}>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_1} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>ПО СМЕТЕ</h3>
                            <p className={styles.item_text}>Стоимость работ и сроки жестко регламентированы договором</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_2} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>ОТВЕТСТВЕННО</h3>
                            <p className={styles.item_text}>Ежегодно мы проводим диагностику Вашего оборудования</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_3} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>ЭКОНОМНО</h3>
                            <p className={styles.item_text}>Оптимальное по цене и качеству оборудование для вашего объекта без посредников</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_4} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>ГАРАНТИЯ</h3>
                            <p className={styles.item_text}>Мы гарантируем качество монтажа системы отопления</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_5} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>БЕЗОПАСНО</h3>
                            <p className={styles.item_text}>Каждый объект проверяется, после монтажа, специалистом более чем по 26 пунктам</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        
        
    )
}


import React from 'react'
import styles from './Services.module.scss'
import { ListBoilers } from '../ListBoilers/ListBoilers'
import icon_3 from '../../image/Portfolio/image_6.webp'
import icon_1 from '../../image/Portfolio/image_4.webp'
import icon_2 from '../../image/Portfolio/image_5.webp'
import icon_6 from '../../image/Portfolio/img.webp'

export const Services = ({isOpen}) => {

    return (
        <section 
            className={styles.main} id='Services'>
                <div className={styles.box}>
                    <h2 className={styles.title} >Мы устанавливаем <span>системы</span></h2>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>ОТОПЛЕНИЯ</h3>
                            <img className={styles.item_image}  src={icon_1} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>ВОДОСНАБЖЕНИЯ</h3>
                            <img className={styles.item_image}  src={icon_2} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>КАНАЛИЗАЦИИ</h3>
                            <img className={styles.item_image}  src={icon_3} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                    </ul>
                </div>
                <div className={styles.type_box} id='Price'>
                    <img className={styles.image} src={icon_6} alt='ромашка'/>
                    <h2 className={styles.type_box_title} ><span>Мы работаем</span> с электрическим, газовым, комбинированным и твердым топливом</h2>
                    <ListBoilers/>
                </div>
        </section>
        
    )
}
import stup_1 from '../image/Portfolio/Stupino_150/img_3.webp'
import stup_2 from '../image/Portfolio/Stupino_150/img_2.webp'
import stup_3 from '../image/Portfolio/Stupino_150/img_1.webp'
import stup_4 from '../image/Portfolio/Stupino_150/img_4.webp'

import dom_1 from '../image/Portfolio/Domodedovo_240/img_1.webp'
import dom_2 from '../image/Portfolio/Domodedovo_240/img_2.webp'
import dom_3 from '../image/Portfolio/Domodedovo_240/img_3.webp'
import dom_4 from '../image/Portfolio/Domodedovo_240/img_4.webp'
import dom_5 from '../image/Portfolio/Domodedovo_240/img_5.webp'


import che_1 from '../image/Portfolio/Domodedovo_200/img_1.webp'
import che_2 from '../image/Portfolio/Domodedovo_200/img_2.webp'
import che_3 from '../image/Portfolio/Domodedovo_200/img_3.webp'
import che_4 from '../image/Portfolio/Domodedovo_200/img_4.webp'

import stu_1 from '../image/Portfolio/Stupino_120/img_1.webp'
import stu_2 from '../image/Portfolio/Stupino_120/img_2.webp'
import stu_3 from '../image/Portfolio/Stupino_120/img_3.webp'
import stu_4 from '../image/Portfolio/Stupino_120/img_4.webp'
import stu_5 from '../image/Portfolio/Stupino_120/img_5.webp'
import stu_6 from '../image/Portfolio/Stupino_120/img_6.webp'

import chek_1 from '../image/Portfolio/Chekhov/img_1.webp'
import chek_2 from '../image/Portfolio/Chekhov/img_2.webp'
import chek_3 from '../image/Portfolio/Chekhov/img_3.webp'
import chek_4 from '../image/Portfolio/Chekhov/img_4.webp'
import chek_5 from '../image/Portfolio/Chekhov/img_5.webp'
import chek_6 from '../image/Portfolio/Chekhov/img_6.webp'

import kas_2 from '../image/Portfolio/image_5.webp'
import kas_3 from '../image/Portfolio/image_6.webp'
import kas_4 from '../image/Portfolio/Kashira/img_1.webp'
import kas_5 from '../image/Portfolio/Kashira/img_2.webp'

export const arrStupino = [{image: stup_1, title: 'Ступино дом 150 кв/м'}, { image: stup_2, title: 'Ступино дом 150 кв/м'}, { image: stup_3, title: 'Ступино дом 150 кв/м'}, {image: stup_4, title: 'Ступино дом 150 кв/м'},];

export const arrDomodedovo = [{image: dom_1, title: 'Домодедово дом 240 кв/м'}, { image: dom_2, title: 'Домодедово дом 240 кв/м'}, { image: dom_3, title: 'Домодедово дом 240 кв/м'}, {image: dom_4, title: 'Домодедово дом 240 кв/м'}, {image: dom_5, title: 'Домодедово дом 240 кв/м'},];

export const arrDomodedovo_2 = [{image: che_1, title: 'Домодедово дом 200 кв/м'}, { image: che_2, title: 'Домодедово дом 240 кв/м'}, { image: che_3, title: 'Домодедово дом 200 кв/м'}, {image: che_4, title: 'Домодедово дом 200 кв/м'},];

export const arrStupino_2 = [{image: stu_1, title: 'Ступино дом 120 кв/м'}, { image: stu_2, title: 'Ступино дом 120 кв/м'}, { image: stu_3, title: 'Ступино дом 120 кв/м'}, {image: stu_4, title: 'Ступино дом 120 кв/м'}, {image: stu_5, title: 'Ступино дом 120 кв/м'}, {image: stu_6, title: 'Ступино дом 120 кв/м'},];

export const arrChekhov = [{image: chek_1, title: 'Чехов дом 160 кв/м'}, {image: chek_2, title: 'Чехов дом 160 кв/м'},{image: chek_3, title: 'Чехов дом 160 кв/м'}, {image: chek_4, title: 'Чехов дом 160 кв/м'}, {image: chek_5, title: 'Чехов дом 160 кв/м'}, {image: chek_6, title: 'Чехов дом 160 кв/м'},];

export const arrKashira = [ {image: kas_2, title: 'Кашира дом 200 кв/м'}, {image: kas_3, title: 'Кашира дом 200 кв/м'}, {image: kas_4, title: 'Кашира дом 200 кв/м'}, {image: kas_5, title: 'Кашира дом 200 кв/м'},];

export const arrPortfolio = [arrStupino_2, arrChekhov, arrKashira, arrStupino, arrDomodedovo, arrDomodedovo_2];




export const arrAdvantages = [{text:'Цена снижена!'}, {text:'Сейчас дешевле на 15%!'}, {text:'Без предоплаты!'}, {text:'АКЦИЯ! До конца месяца'}, {text:'Выезд инженера БЕСПЛАТНО!'}, {text:'Гарантия КАЧЕСТВА!'},];

export const arrVideo =[{video: 'https://youtu.be/KGLP1arSA0c'}, {video: 'https://youtu.be/xOCJcLJZCVY'}, {video: 'https://youtu.be/L4wD6otVt_Y'}, {video: 'https://youtu.be/wJCremVOy9c'},  {video: 'https://youtu.be/18OBqyDlyJI'}, {video: 'https://youtu.be/f66l4XUKKjc'},]
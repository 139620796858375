import React from 'react'
import { arrAdvantages } from '../../utils/data'
import styles from './SliderAdvantages.module.scss'
import { v4 as uuidv4 } from 'uuid'


export const SliderAdvantages = () => {

    return (
            <div className={styles.container}>
                    <div className={styles.items_wrap}>
                        <ul className={`${styles.items} ${styles.marquee}`}>
                            {arrAdvantages.map(item => {
                                const keyUid = uuidv4()
                                return(
                                    <li className={styles.item} key={keyUid}>
                                        <div className={styles.circle}></div>
                                        <p className={styles.text}>{item.text}</p>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className={`${styles.items} ${styles.marquee}`} aria-hidden="true">
                            {arrAdvantages.map(item => {
                                const keyUid = uuidv4()
                                return(
                                    <li className={styles.item} key={keyUid}>
                                        <div className={styles.circle}></div>
                                        <p className={styles.text}>{item.text}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
            </div>
    )
}
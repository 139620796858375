import React from 'react'
import styles from './Deadlines.module.scss'
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/img_4.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_6 from '../../image/Deadlines/img_6.svg'

export const Deadlines = ({isOpen}) => {

    return (
        <section className={styles.main} id='Deadlines'>
            <div className={styles.container}>
            <h2 className={styles.title} >ЗАКАЖИТЕ СЕЙЧАС, СДЕЛАЕМ БЫСТРО!</h2>
            <ul className={styles.list}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете заявку на сайте или звоните нам по телефону</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Уточняем особенности вашего дома и готовим предварительную смету</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Выезд</h3>
                            <p className={styles.item_text}>На объект выезжает технический специалист для точных замеров</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Проект</h3>
                            <p className={styles.item_text}>Готовим рабочий проект, согласовываем смету и стоимость работ, подписываем договор</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Поставка</h3>
                            <p className={styles.item_text}>Начинаем комплектацию объекта - поставляем только качественную продукцию напрямую у заводов изготовителей без наценок</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Монтаж</h3>
                            <p className={styles.item_text}>Самый дешевый вариант - сразу сделать хорошо! Мы делаем свою работу так, чтобы исключить возможность даже мелких проблем в будущем</p>
                        </li>
                    </ul>
                    <button className={styles.button} type='button' onClick={isOpen}>заказать систему</button>
            </div>   
        </section>
        
        
    )
}
